import Header from"../../components/Header";
import HomeSection from"./HomeSection";
import TableSection from"./TableSection";

const SyCredits = () => {

  return(
    <>
      <Header />
      <HomeSection />
      <TableSection />
    </>
  )
}
export default SyCredits;
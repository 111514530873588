// import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
// import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
// import '@splidejs/react-splide/css';
// import '@splidejs/react-splide/css/core';
import ProtocolRevenue from "../../assets/stake/protocolRevenue.png";
import Governance from "../../assets/stake/governance.png";
import Benefit from "../../assets/stake/benefits.png";
// import HandHoldingCircle from '../../assets/hand-holding-dollar-circle.png'; 
import BoxAnim from "../../components/BoxAnim";
import IconContainer from "./Common/IconContainer";
import YeildEllipse1 from "../../assets/yield/yeild-ellipse-1.svg";
import StakeBG from "../../assets/stake/stake-bg.png";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const StakeSection = () => {

  return(
    <section className='stake-section' >
      <img className="stake-bg" src={StakeBG} />
      <div className='site-container'>
        <div className='d-md-flex align-items-center justify-content-between title-container'>
          <div>
            <h2 className="text-title-animation">
              STAKE <span className="green">SYNTH</span> FOR <span className="green">veSYNTH</span>
            </h2>
            <div className='desc'>
              Get a host of staking benefits by time-locking SYNTH.
            </div>
          </div>

          <div
            className='buy-synth-button d-flex flex-column justify-content-center align-items-center'
          >
            BUY SYNTH
          </div>
        </div>
        
        <div className='box-wrapper'>
          <div className='box'>
            <img src={ProtocolRevenue} alt='' />
            <div className="label-wrapper">
              <div className='label'> Protocol revenue </div>
              <div className='name'> Access to real multi-chain yield. </div>
            </div>
          </div>
          
          <div className='box'>
            <img src={Governance} alt='' />
            <div className="label-wrapper">
              <div className='label'> Protocol governance </div>
              <div className='name'> Influence gauge weights for syASSET/ASSET LP farming rewards, revenue distribution, syASSET listing proposals, etc. </div>
            </div>
          </div>
          
          <div className='box'>
            <img src={Benefit} alt='' />
            <div className="label-wrapper">
              <div className='label'> Additional benefits </div>
              <div className='name'> Boosted syASSET/ASSET LP farming rewards, bribes to influence protocol governance proposals, and airdrops from #BUIDLonSYNTHR protocols. </div>
            </div>
          </div>
        </div>
      </div>
      
      <Swiper
        spaceBetween={40}
        slidesPerView={7}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className={`mySwiper w-100 stake-box-slider`}
        breakpoints={{
          0:{
            spaceBetween: 30,
            slidesPerView: 1,
          },
          480:{
            spaceBetween: 30,
            slidesPerView: 1,
          },
          768: {
            spaceBetween: 40,
            slidesPerView: 2,
          },
        }}
      >
        <SwiperSlide className='box'>
          <img src={ProtocolRevenue} alt='' />
          <div className="label-wrapper">
            <div className='label'> Protocol revenue </div>
            <div className='name'> Access to real multi-chain yield. </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className='box'>
          <img src={Governance} alt='' />
          <div className="label-wrapper">
            <div className='label'> Protocol governance </div>
            <div className='name'> Influence gauge weights for DEX LP incentives, revenue distribution, syASSET listing proposals, etc.</div>
          </div>
        </SwiperSlide>

        <SwiperSlide className='box'>
          <img src={Benefit} alt='' />
          <div className="label-wrapper">
            <div className='label'> Additional benefits </div>
            <div className='name'> Boosted DEX LP farming rewards and bribes to influence protocol governance proposals. </div>
          </div>
        </SwiperSlide>
      </Swiper>

    </section>
  )
}
export default StakeSection;
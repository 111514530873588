// import ScrollContainer = React.lazy(() => import("../../components/ScrollContainer"));
import PageWrapperAnim from "../../components/PageWrapperAnim";
import Header from "../../components/Header";
import TopSection from "./TopSection";
import PCEsection from "./PCESection";
import FeatureSection from "./FeatureSection";
import ChainsAndGrants from "./ChainsAndGrants";
import StakeSection from "./StakeSection";
import Backers from "./Backers";
import Whitepaper from "./Whitepaper";
import Partners from "./Partners";
import Yield from "./yield"; 
// import LiquidateSection from "./Liquidate";
import Footer from "../../components/Footer";
import DefiFirstSection from "./DefiFirstSection";
import EcoSystemSection from "./EcoSystemSection";
import Grants from "./Grants";
import SytherBadge from "./SytherBadge";

const Home = () => {

  return(
    <>
      <PageWrapperAnim />
      <Header />
      <TopSection />
      <PCEsection />
      <DefiFirstSection />
      <EcoSystemSection />
      <Yield />
      <Grants />
      {/* <ChainsAndGrants /> */}
      {/* <FeatureSection /> */}
      <StakeSection />
      <Backers />
      {/* <Partners /> */}
      {/* <LiquidateSection /> */}
      {/* <Whitepaper /> */}
      <SytherBadge/>
      {/* <Footer /> */}
    </>
  )
}
export default Home;
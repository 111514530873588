import { useEffect, useState } from "react";
import Uniswap from "../../assets/partners/UNISWAP-1.png";
import TraderJoe from "../../assets/partners/TRADERJOE.png";
import Swaped from "../../assets/partners/swapped.png";
import Mute from "../../assets/partners/MUTE.png";
import Paranoma from "../../assets/partners/PANAROMA.png";
import Kaidex from "../../assets/partners/KAIDEX.png";
import SparrowSwap from "../../assets/partners/SPARROW SWAP.png";
import ApeSwap from "../../assets/partners/apeswap-1.png";
import Cetus from "../../assets/partners/CETUS.png";
import Maia from "../../assets/partners/MAIA-1.png";
import Illuminex from "../../assets/partners/ILLUMINEX-1.png";
import OpenOcean from "../../assets/partners/OPENOCEAN.png";
import Router from "../../assets/partners/ROUTER.png";
import Woofi from "../../assets/partners/WOOFI-1.png";
import Unizenn from "../../assets/partners/UNIZEN.png";
import RocketX from "../../assets/partners/ROCKETX-1.png";
import Rangoo from '../../assets/partners/RANGO.png';
import Carbonn from '../../assets/partners/CARBON.png';
import Axelar from "../../assets/partners/AXELAR.png";
import LayerZero from "../../assets/partners/LAYER-0.png";
import Pyth from "../../assets/partners/PYTH-1.png";
import Chainlink from "../../assets/partners/CHAINLINK.png";
import SupraOracles from "../../assets/partners/SUPRA.png";
import TheGraph from "../../assets/partners/the-graph.png";

import Halborn from "../../assets/partners/halborn.png";
import Infura from "../../assets/partners/infura.png";
import QuickNode from "../../assets/partners/quick-node.png";

import CustomCarousel from "../../components/CustomCarousel";

import Binnace from "../../assets/Wallets/binnace.png";
import Bybit from "../../assets/Wallets/bybit.png";
import Coinbase from "../../assets/Wallets/coinbase.png";
import Metamask from "../../assets/Wallets/metamask.png";
import Okx from "../../assets/Wallets/okx.png";
import Rabby from "../../assets/Wallets/rabby.png";
import Trust from "../../assets/Wallets/trust.png";


const Tabs = [
    "DEXs", "DEX aggregators", 'Wallets',"Infra", "#BUIDLonSYNTHR"
]
const TabContent = {
    "DEXs": [
        Uniswap, TraderJoe, Swaped, Mute, Paranoma, Kaidex, SparrowSwap, ApeSwap, Cetus, Maia, Illuminex
    ],
    "DEX aggregators": [OpenOcean, Woofi, Unizenn, RocketX, Rangoo, Carbonn],
    "Infra": [Axelar, LayerZero, Pyth, Chainlink, SupraOracles, TheGraph, Halborn, Infura, QuickNode, Router],
    'Wallets': [Binnace, Coinbase, Bybit, Metamask, Okx, Rabby, Trust],
}
const EcoSystemSection = () => {
    const [activeTab, setActiveTab] = useState(Tabs[0]);
    return (
        <div className="ecoS-wrapper">
            <div className="ecoS-heading text-title-animation">
                ECOSYSTEM
            </div>
            <div className="ecoS-tab-wrapper">
                {
                    Tabs.map((tab, ind) => {
                        return (<span className={`${activeTab === tab ? 'active' : ''} ${ind === 4 ? 'disabled' : ''} tabs`} key={ind} onClick={() => {ind !== 4 && setActiveTab(tab)}}>
                            {tab}
                        </span>)
                    })
                }
            </div>
            <div className="tab-content-wrapper">
                {Object.keys(TabContent).map((id) => {
                    return (
                        <div className={`${activeTab === id ? 'active': id+activeTab} ecoS-slider`} key={id}>
                            <CustomCarousel
                                logos={TabContent[id]}
                                classnames="ecoS-carousel"
                                gap="22px"
                                orient="-100"
                                clones= {id === "Infra" ? 7 : undefined}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default EcoSystemSection;
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import { useEffect, useRef } from 'react';

const CustomCarousel = ({ logos, classnames, direction, slidePerView, gap, perMove, clones, customContent, goToSlide, interval, onMove, autoHeight, height, autoplay, center }) => {
  const splide = useRef();
  const splideOptions =  {
    rewind: false,
    gap   : gap ? gap : '40px',
    drag   : 'free',
    type   : 'loop',
    focus  : center ? center : '',
    perPage: slidePerView ? slidePerView : 3,
    width: "100%",
    speed: 1000,
    autoWidth: true,
    direction: direction ? direction : undefined,
    autoHeight : autoHeight,
    height: height,
    perMove: perMove ? perMove : 1,
    arrows: false,
    clones: clones ? clones: undefined,
    pagination: false,
    autoScroll: false,
    autoplay: autoplay ? autoplay : true,
    interval: interval ? interval : 2000,
    isNavigation: true,
    updateOnMove: true,
    breakpoints: {
      480: {
        perPage: 3,
        perMove: 1,
        speed: 1000,
        gap : gap ? gap : '23px',
      },
    },
  } 

  useEffect(() => {
    splide?.current.go(goToSlide);
  },[goToSlide]);

  useEffect(() => {
      onMove && splide?.current?.splide?.event?.on('move',onMove);
      return () => {
        onMove && splide?.current?.splide?.event?.off('move',onMove);
      }
  },[splide])

  return(
    <Splide ref={splide} hasTrack={ false } aria-label="..."  options={splideOptions} style={{ width: "100%", overflow: "hidden" }} extensions={{ AutoScroll }}>
      <SplideTrack>
        {logos.map((item, index) => (
          <SplideSlide className={`${classnames} ${direction === 'ttb' ? '' : 'logos-slide d-flex justify-content-center'}`} key={`${item}-${index}`}>
            { customContent ?  item : <img src={item} alt='' />}
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  )
}
export default CustomCarousel;
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import RoutesConfig from './RoutesConfig';
// import "rolly.js/css/style.css";
// import "locomotive-scroll/dist/locomotive-scroll.min.css";

function App() {

  return (
    <RoutesConfig />
  );
}

export default App;

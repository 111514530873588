import { useEffect, useState } from 'react'; 
import Logo from "../assets/logo-light.png"; 

const PageWrapperAnim = () => {

  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000);
  }, [])

  return(
    <div className={`loader d-flex align-items-center justify-content-center ${show ? "show" : ""}`}>
      <img src={Logo} alt='' />
    </div>
  )
}

export default PageWrapperAnim;
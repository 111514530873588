import { useRef } from "react";
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
// import EthIcon from "../../assets/yield/eth-icon.png";
// import USDTIcon from "../../assets/yield/USDT.png";
// import USDCIcon from "../../assets/yield/USDC.png";
// import syETH from "../../assets/yield/syETH.png";
// import syEthAndEth from "../../assets/yield/syEth-and-eth.png";
// import syTokenIcon from "../../assets/synthr-coin.png";
// import stEth from "../../assets/yield/stEth.png";
// import YeildEllipse from "../../assets/yield/yeild-ellipse.svg";
// import YeildEllipse1 from "../../assets/yield/yeild-ellipse-1.svg";
// import step1 from "../../assets/yield/step1.png";
// import step2 from "../../assets/yield/step2.png";
// import step3 from "../../assets/yield/step3.png";
// import step4 from "../../assets/yield/step4.png";
import Farm from "../../assets/yield/Farm.png";
import Mint from "../../assets/yield/Mint.png";
import Stake from "../../assets/yield/Stake.png";


// import CustomProgressbar from "./CustomProgressBar";
// import CustomCarousel from "../../components/CustomCarousel";
// import AnimatedText from "../../components/AnimatedText";

// const stepValues =[
//   { label: 'STEP 01', step: 1 },
//   { label: 'STEP 02', step: 2 },
//   { label: 'STEP 03', step: 3 },
//   { label: 'STEP 04', step: 4 }
//   ]
const Yield = () => {
  // const [goToSlide, setGoToSlide] = useState();

  const splide = useRef();
  const splideOptions =  {
    rewind: false,
    gap   : '20px',
    drag   : 'free',
    type   : 'loop',
    focus  : 'center',
    perPage: 1,
    width: "100%",
    speed: 1000,
    // autoWidth: true,
    // direction: direction ? direction : undefined,
    // autoHeight : autoHeight,
    // height: height,
    perMove: 1,
    arrows: false,
    // clones: clones ? clones: undefined,
    pagination: false,
    autoScroll: false,
    autoplay: true,
    interval: 2000,
    isNavigation: true,
    updateOnMove: true,
    breakpoints: {
      480: {
        perPage: 1,
        perMove: 1,
        speed: 1000,
        gap : '23px',
      },
    },
  } 

  return(
    <section className='yield-section'>
      <div className='site-container'>
        <div className="title-container">
          <h2 className="text-title-animation">
            <span className="green">SUPERCHARGED APR </span> FOR LIQUIDITY PROVIDERS           
          </h2>
        </div>

        <div className="yeild-grid-wrapper d-md-grid d-none">
          <div className="box-wrapper">
            <img src={Mint} alt='mint' className="icon" />

            <div className="label">
              Mint omnichain syASSETS
            </div>
            <div className="description">
              Earn syUSD rewards.
            </div>
          </div>

          <div className="box-wrapper">
            <img src={Farm} alt='farm' className="icon" />

            <div className="label">
              Farm syASSET/ASSET LP tokens
            </div>
            <div className="description">
              Earn SYNTH rewards.
            </div>
          </div>

          <div className="box-wrapper">
            <img src={Stake} alt='stake' className="icon" />

            <div className="label">
              Stake SYNTH for veSYNTH
            </div>
            <div className="description">
              Earn syUSD and SYNTH rewards.
            </div>
          </div>
        </div>
      </div>

      <div className="yeild-grid-wrapper d-md-none">
        <Splide ref={splide} hasTrack={ false } aria-label="..."  options={splideOptions} style={{ width: "100%", overflow: "hidden" }} extensions={{ AutoScroll }}>
          <SplideTrack>
            <SplideSlide className='box-wrapper'>
              <img src={Mint} alt='mint' className="icon" />

              <div className="label">
                Mint omnichain syASSETS
              </div>
              <div className="description">
                Earn syUSD rewards.
              </div>
            </SplideSlide>
            
            <SplideSlide className='box-wrapper'>
              <img src={Farm} alt='farm' className="icon" />

              <div className="label">
                Farm syASSET/ASSET LP tokens
              </div>
              <div className="description">
                Earn SYNTH rewards.
              </div>
            </SplideSlide>

            <SplideSlide className='box-wrapper'>
              <img src={Stake} alt='stake' className="icon" />

              <div className="label">
                Stake SYNTH for veSYNTH
              </div>
              <div className="description">
                Earn syUSD and SYNTH rewards.
              </div>
            </SplideSlide>
          </SplideTrack>
        </Splide>
      </div>
    </section>
  )
}
export default Yield;
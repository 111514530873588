import {ReactComponent as GoldMedal} from "../../assets/goldMedal.svg";
import {ReactComponent as SilverMedal} from "../../assets/silverMedal.svg";
import {ReactComponent as BronzeMedal} from "../../assets/bronzeMedal.svg";
import {ReactComponent as UpDownArrows} from "../../assets/up-down-arrows.svg";
import { useState } from "react";

const Leaderboard = ({users, leaderboard, setUsers, updatedDateAndTime, loading, showNavbar}) => {

  const [sortOrder, setSortOrder] = useState({ totalReward: true, baseReward: true, campRewards: true, transactions: true, chains: true, addColSame: true, bridge: true, mint: true, sameChainSwap: true, burn: true, flagForLiquidation: true, liquidation: true, withdrawCollateral: true, escrowRewards: true, withdrawRewards: true})

  const shortAddress = (address) => {
    const AddressLength = address.length;
    const last4LetterInd = AddressLength - 4;
    const first4Letters = AddressLength > 4 ? address.substring(0, 4) : address;
    const lastLetters = AddressLength > 4 ? address.substring(last4LetterInd, AddressLength) : address;
    return `${first4Letters}....${lastLetters}`;
  }

  const title = (title, sortKey) => {
    return(
      <div className="d-flex align-items-center">
        {title}   
        <div className='up-down-arrows' onClick={() => changeOrder(sortKey)}> <UpDownArrows /> </div>        
      </div>
    )
  }

  const changeOrder = (sortKey) => {
    const newUser = [...users];
    if(sortKey === "tansaction"){
      if(sortOrder.transactions){
        newUser.sort((a, b) => a.transactions - b.transactions)
        setSortOrder({...sortOrder, transactions: false})
      }
      else{
        newUser.sort((a, b) => b.transactions - a.transactions)
        setSortOrder({...sortOrder, transactions: true})
      }
    }
    else if(sortKey === "Add Collateral"){
      if(sortOrder.addColSame){
        newUser.sort((a, b) => a.addCollateral - b.addCollateral)
        setSortOrder({...sortOrder, addColSame: false})
      }
      else{
        newUser.sort((a, b) => b.addCollateral - a.addCollateral)
        setSortOrder({...sortOrder, addColSame: true})
      }
    }
    else if(sortKey === "Bridge"){
      if(sortOrder.bridge){
        newUser.sort((a, b) => a.bridge - b.bridge)
        setSortOrder({...sortOrder, bridge: false})
      }
      else{
        newUser.sort((a, b) => b.bridge - a.bridge)
        setSortOrder({...sortOrder, bridge: true})
      }
    }
    else if(sortKey === "Mint"){
      if(sortOrder.mint){
        newUser.sort((a, b) => a.mintTnx - b.mintTnx)
        setSortOrder({...sortOrder, mint: false})
      }
      else{
        newUser.sort((a, b) => b.mintTnx - a.mintTnx)
        setSortOrder({...sortOrder, mint: true})
      }
    }
    else if(sortKey === "Same-chain swap"){
      if(sortOrder.sameChainSwap){
        newUser.sort((a, b) => a.sameChainSwap - b.sameChainSwap)
        setSortOrder({...sortOrder, sameChainSwap: false})
      }
      else{
        newUser.sort((a, b) => b.sameChainSwap - a.sameChainSwap)
        setSortOrder({...sortOrder, sameChainSwap: true})
      }
    }
    // else if(sortKey === "Cross-chain swap"){
    //   if(sortOrder.corssChainSwap){
    //     newUser.sort((a, b) => a.corssChainSwap - b.corssChainSwap)
    //     setSortOrder({...sortOrder, corssChainSwap: false})
    //   }
    //   else{
    //     newUser.sort((a, b) => b.corssChainSwap - a.corssChainSwap)
    //     setSortOrder({...sortOrder, corssChainSwap: true})
    //   }
    // }
    else if(sortKey === "Burn"){
      if(sortOrder.burn){
        newUser.sort((a, b) => a.burn - b.burn)
        setSortOrder({...sortOrder, burn: false})
      }
      else{
        newUser.sort((a, b) => b.burn - a.burn)
        setSortOrder({...sortOrder, burn: true})
      }
    }
    else if(sortKey === "Flag for Liquidation"){
      if(sortOrder.flagForLiquidation){
        newUser.sort((a, b) => a.flagForLiquidation - b.flagForLiquidation)
        setSortOrder({...sortOrder, flagForLiquidation: false})
      }
      else{
        newUser.sort((a, b) => b.flagForLiquidation - a.flagForLiquidation)
        setSortOrder({...sortOrder, flagForLiquidation: true})
      }
    }
    else if(sortKey === "Liquidation"){
      if(sortOrder.liquidation){
        newUser.sort((a, b) => a.liquidate - b.liquidate)
        setSortOrder({...sortOrder, liquidation: false})
      }
      else{
        newUser.sort((a, b) => b.liquidate - a.liquidate)
        setSortOrder({...sortOrder, liquidation: true})
      }
    }
    else if(sortKey === 'Withdraw collateral'){
      if(sortOrder.withdrawCollateral){
        newUser.sort((a, b) => a.withdrawCollateral - b.withdrawCollateral)
        setSortOrder({...sortOrder, withdrawCollateral: false})
      }
      else{
        newUser.sort((a, b) => b.withdrawCollateral - a.withdrawCollateral)
        setSortOrder({...sortOrder, withdrawCollateral: true})
      }
    }
    else if(sortKey === "Escrow rewards"){
      if(sortOrder.escrowRewards){
        newUser.sort((a, b) => a.escrowRewards - b.escrowRewards)
        setSortOrder({...sortOrder, escrowRewards: false})
      }
      else{
        newUser.sort((a, b) => b.escrowRewards - a.escrowRewards)
        setSortOrder({...sortOrder, escrowRewards: true})
      }
    }
    else if(sortKey === "Withdraw rewards"){
      if(sortOrder.withdrawRewards){
        newUser.sort((a, b) => a.withdrawRewards - b.withdrawRewards)
        setSortOrder({...sortOrder, withdrawRewards: false})
      }
      else{
        newUser.sort((a, b) => b.withdrawRewards - a.withdrawRewards)
        setSortOrder({...sortOrder, withdrawRewards: true})
      }
    }
    
    setUsers(newUser)
  }

  const getRole = (roleNumber) => {
    let role = '';
    switch (roleNumber) {
      case 3:
        role = 'OG'
        break;
      case 2:
        role = 'EA'
        break;
      case 1:
        role = 'Public'
        break;
      default:
        break;
    }
    return role;
  }

  const getBadge = (badgeName) => {
    let badge = 'None';
    switch (badgeName) {
      case 0:
        badge = 'None'
        break;
      case 1:
        badge = 'Recruit'
        break;
      case 2:
        badge = 'Guardian'
        break;
      case 3:
        badge = 'Trooper'
        break;
      case 4:
        badge = 'Crusader'
        break;
      case 5:
        badge = 'Master'
        break;
      case 6:
        badge = 'Wizard'
        break;
      case 7:
        badge = 'Legend'
        break;
      case 8:
        badge = 'Immortal'
        break;
      default:
        badge = 'None'
        break;
    }
    return badge;
  }

  return(
    <div className="leaderborad-wrapper">
      <div className={`d-md-flex align-items-center justify-content-between title-container ${!showNavbar ? 'ps-0' : '' }`}>
        <div className="title"> Transaction history  </div>
        {
          updatedDateAndTime &&
          <div className="update-date-and-time">Next update on: {updatedDateAndTime}</div>
        }
      </div>
      {
        loading ? 
          <div className="empty-leaderboard-text">Loading...</div>
        :
        users && users !== null ?
          <div className="table-wrapper">
            <table className={`leaderboard-table ${!showNavbar ? 'open-table' : ''}`}>
              <thead>
                <tr>
                  <td className={`${!showNavbar ? 'ps-0' : '' }`}> 
                    {title("Rank", "rank")}
                  </td>
                  <td> 
                    User 
                  </td>
                  <td> 
                    Role 
                  </td>
                  <td>Badge</td>
                  <td> 
                    {title("Total transactions", "tansaction")}
                  </td>
                  <td>
                    {title("Add collateral", "Add Collateral")}
                  </td>
                  <td> 
                    {title("Mint", "Mint")}
                  </td>
                  <td> 
                    {title("Swap", "Same-chain swap")}
                  </td>
                  {/* <td> 
                    {title("Cross-chain swap", "Cross-chain swap")}
                  </td> */}
                  <td> 
                    {title("Bridge", "Bridge")}
                  </td>
                  <td> 
                    {title("Burn", "Burn")}
                  </td>
                  <td> 
                    {title("Withdraw collateral", "Withdraw collateral")}
                  </td>
                  <td> 
                    {title("Flag for liquidation", "Flag for Liquidation")}
                  </td>
                  <td> 
                    {title("Liquidate", "Liquidation")}
                  </td>
                  <td> 
                    {title("Escrow liq. rewards", "Escrow rewards")}
                  </td>
                  <td> 
                    {title("Withdraw liq. rewards", "Withdraw rewards")}
                  </td>
                </tr>
              </thead>

              <tbody>
                {
                  users.length > 0 &&
                  users.map((item, index) => (
                    <>
                    <tr key={`${item.discord_tag}-${index}`}>
                      <td className={`${!showNavbar ? 'ps-0' : '' }`}>
                        {users.length === 1 ? <div>-</div> : 
                          (item.ind === 0 ? <GoldMedal /> : item.ind === 1 ? <SilverMedal /> : item.ind === 2 ? <BronzeMedal /> : item.ind + 1)
                        }
                      </td>
                      <td>{item.discord_tag ? item.discord_tag : shortAddress(item.wallet_address)}</td>
                      <td>{getRole(item.role)}</td>
                      <td>{getBadge(item.badge)}</td>
                      <td>{item.transactions.toLocaleString('en-US')}</td>
                      <td>{item.addCollateral.toLocaleString('en-US')}</td>
                      <td>{item.mintTnx.toLocaleString('en-US')}</td>
                      <td>{item.sameChainSwap.toLocaleString('en-US')}</td>
                      <td>{item.bridge.toLocaleString('en-US')}</td>
                      <td>{item.burn.toLocaleString('en-US')}</td>
                      <td>{item.withdrawCollateral.toLocaleString('en-US')}</td>
                      <td>{item.flagForLiquidation.toLocaleString('en-US')}</td>
                      <td>{item.liquidate.toLocaleString('en-US')}</td>
                      <td>{item.escrowRewards.toLocaleString('en-US')}</td>
                      <td>{item.withdrawRewards.toLocaleString('en-US')}</td>
                    </tr>
                    </>
                  ))
                }
              </tbody>
            </table>
          </div>
          :
          <div className="empty-leaderboard-text">
            {leaderboard === 'Campaign Leaderboard' ? "There is currently no active campaign." : "You currently have no transactions." }
          </div>
      }
    </div>
  )
}
export default Leaderboard;
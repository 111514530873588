import IconContainer from "./Common/IconContainer";
import SlippageFree from "../../assets/defi-first/slippage-icon.png";
import Omnichain from "../../assets/defi-first/omnichain-icon.png";
import Multichain from "../../assets/defi-first/multichain-icon.png";
import Crosschain from "../../assets/defi-first/crosschain.png";
import OptionVault from "../../assets/defi-first/option-vault.png";
import Tokenized from "../../assets/defi-first/tokenized-icon.png";
import DefiCore from "../../assets/defi-first/defi-core.png";
import LineAngle from "../../assets/defi-first/line-angle.png";
import LineStraight from "../../assets/defi-first/line-angle-2.png";
import DexAggregator from  "../../assets/defi-first/dexAggregator.png";
import AiPowered from "../../assets/defi-first/ai.png";
import SyntheticRwa from "../../assets/defi-first/synthetic-icon.png";
import useIsMobile from "./Common/useIsMobile";
// import AnimatedText from "../../components/AnimatedText";

const DefiFirstSection = () => {
    const isMobile = useIsMobile();
    return (
        <div className="defi-wrapper">
            <div className="site-container d-md-flex">
                <div className="defi-icons left">
                    <div className="defi-icon-wrapper">
                        <div className="info">
                            <span className="title">Synthetic</span>
                            <span className="desc">RWAs</span>
                        </div>
                        <IconContainer Icon={SyntheticRwa} />
                        <div className={`step-style completed`}>
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                        </div>
                    </div>
                    <div className="defi-icon-wrapper">
                        <div className="info">
                            <span className="title">AI-powered </span>
                            <span className="desc">capital efficiency</span>
                        </div>
                        <IconContainer Icon={AiPowered} />
                        <div className={`step-style completed`}>
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                        </div>
                    </div>
                    <div className="defi-icon-wrapper">
                        <div className="info">
                            <span className="title">Slippage-free</span>
                            <span className="desc">cross-chain swaps</span>
                        </div>
                        <IconContainer Icon={SlippageFree} />
                        <div className={`step-style completed`}>
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                        </div>
                    </div>
                    <div className="defi-icon-wrapper">
                        <div className="info">
                            <span className="title">Real multi-chain</span>
                            <span className="desc">yield against LRTs</span>
                        </div>
                        <IconContainer Icon={Multichain} />
                        <div className={`step-style completed`}>
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                        </div>
                    </div>
                </div>
                <div className="defi-core">
                    { isMobile || <>
                        <img className="line-angle lt" src={LineAngle} alt='' />
                        <img className="line-angle rt" src={LineAngle} alt='' />
                        <img className="line-angle lb" src={LineAngle} alt='' />
                        <img className="line-angle rb" src={LineAngle} alt='' /> 
                        <div className="straight-lines">
                            <img className="line-straight top-right" src={LineStraight} alt='' />
                            <img className="line-straight top-left" src={LineStraight} alt='' />
                            <img className="line-straight bottom-right" src={LineStraight} alt='' />
                            <img className="line-straight bottom-left" src={LineStraight} alt='' />
                        </div>
                    </> }
                    <img className="core-image" src={DefiCore} alt='' />
                    <span className="core-text">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <div className="sup">
                                ZERO-SLIPPAGE
                            </div>
                            <div className="text text-title-animation green">
                                OMNICHAIN
                            </div>
                            <div className="liquidity-layer-text">
                                LIQUIDITY LAYER
                                <a href="https://synthr.gitbook.io/home/litepaper/product" target="_blank" className="learn-more-link">
                                    Learn more  
                                </a>
                            </div>

                        </div>
                    </span>
                </div>
                <div className="defi-icons right">
                    <div className="defi-icon-wrapper">
                        <div className={`step-style completed`}>
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                        </div>
                        <IconContainer Icon={Crosschain} />
                        <div className="info">
                            <span className="title">Cross-chain</span>
                            <span className="desc">perpetuals</span>
                        </div>
                    </div>
                    <div className="defi-icon-wrapper">
                        <div className={`step-style completed`}>
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                        </div>
                        <IconContainer Icon={Omnichain} />
                        <div className="info">
                            <span className="title">Omnichain</span>
                            <span className="desc">option vaults</span>
                        </div>
                    </div>
                    <div className="defi-icon-wrapper">
                        <div className={`step-style completed`}>
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                        </div>
                        <IconContainer Icon={DexAggregator} />
                        <div className="info">
                            <span className="title">Cross-chain </span>
                            <span className="desc">DEX aggregators</span>
                        </div>
                    </div>
                    <div className="defi-icon-wrapper">
                        <div className={`step-style completed`}>
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                            <span className={`ripple wave-span`}  />
                        </div>
                        <IconContainer Icon={Tokenized} />
                        <div className="info">
                            <span className="title">Omnichain</span>
                            <span className="desc">prediction markets</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefiFirstSection;
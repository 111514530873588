import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import BoxAnim from "../../components/BoxAnim";
import { inView } from "framer-motion";
import recruit from "../../assets/badges/recruit.png";
import master from "../../assets/badges/master.png";
import crusader from "../../assets/badges/crusader.png";
import guardian from "../../assets/badges/guardian.png";
import trooper from "../../assets/badges/trooper.png";
import wizard from "../../assets/badges/wizard.png";
import legend from "../../assets/badges/legend.png";
import immortel from "../../assets/badges/immortel.png";
import BoxAnim from "../../components/BoxAnim";
import useIsMobile from "./Common/useIsMobile";
import SythBg from "../../assets/SytherBadge_bg.png";
import CustomCarousel from "../../components/CustomCarousel";
import AnimatedText from "../../components/AnimatedText";

import Footer from "../../components/NewFooter";


const badge1 = [recruit,guardian,trooper,legend]
const badge2 = [crusader,master,wizard,immortel]
const SytherBadge = () => {
  const isMobile = useIsMobile();

  useEffect(() => {
    const title = document.querySelectorAll(".text-title-animation");
    title.forEach((ele) => {
      inView(ele, () => {
        addAnimation(ele)
        return () => addAnimation(ele,true);
      })
    })
  })

  const addAnimation = (ele, stop) => {
    const spanTags = ele.querySelectorAll("span")
    spanTags.forEach(spanTag => {
      if (stop) {
        spanTag.classList.remove("text-animation");
      } else {
        spanTag.classList.add("text-animation");
      }
    });
  }

  return(
    <section className='SytherBadge-section' >
      <div className="site-container d-md-flex align-items-center justify-content-between">
        <div className='sythnrBadge-left'>
          <div className='title text-title-animation'>
            SYNTHR <span className="green">BADGE</span> COLLECTION
          </div>
          <div className="synthrSubtitle">
            Complete milestones to earn badges and boost your syCREDITS.
          </div>
          <Link to="https://mvp.synthr.io" target="_blank" rel='noopeaner' className='sytherCollect' >
            COLLECT NOW
          </Link> 
        </div>
        <div className="synthrBadgeImg">
          <div className={`synthr-carousel-container scc-1`}>
            <CustomCarousel
              logos={badge1}
              classnames="synthr-carousel-right"
              gap={`${isMobile ? "9.6px" : "14px"}`}
              direction={'ttb'}
              autoHeight={"true"}
              height={"true"}
              />
          </div>
          <div delay="0.7" className={`synthr-carousel-container`}>
            <CustomCarousel
              logos={badge2}
              classnames="synthr-carousel-right"
              gap={`${isMobile ? "9.6px" : "14px"}`}
              direction={'ttb'}
              autoHeight={"true"}
              height={"true"}
            />
          </div>
          <div className="synthrBadge-rec">

          </div>
          <div className="synthrBadge-rec-bottom">
            
          </div>
        </div>
        
        <Footer />
        <img className="syth-bg" src={SythBg} />
      </div>
    </section>
  )
}
export default SytherBadge;
import { Link } from 'react-router-dom';
import { ReactComponent as Discord } from "../assets/socials/discord.svg";
import { ReactComponent as Telegram } from "../assets/socials/telegram.svg";
import { ReactComponent as Github } from "../assets/socials/github.svg";
import { ReactComponent as Zealy } from "../assets/socials/zealy.svg";
import { ReactComponent as Gitbook } from "../assets/socials/gitbook.svg";
import { ReactComponent as X } from "../assets/socials/xlogo.svg";

const Footer = ({ bg }) => {
  return(
    <div className={`footer-bar d-flex align-items-center ${bg ? 'black-bg' : ''}`}>
      <div className="site-container">
        <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">

          <div className="copyright-text order-2 order-md-1">Copyright © 2024 Intercap Foundation. All rights reserved.</div>

          <div className='d-flex align-items-center justify-content-center f-social-icons order-1 order-md-2'>
          
            <Link to='https://twitter.com/synthr_defi' className='social-media-wrapper' target="_blank">
              <X className="x-logo" />
            </Link>
          
            <Link to='https://discord.gg/KkUzFPanf5' className='social-media-wrapper' target="_blank">
              <Discord width={"50px"} height={"50px"} />
            </Link>
          
            <Link to='https://t.me/synthrcommunitychannel' className='social-media-wrapper' target="_blank">
              <Telegram width={"50px"} height={"50px"}/>
            </Link>
          
            <Link to='https://zealy.io/c/synthr/questboard' className='social-media-wrapper' target="_blank">
              <Zealy width={"21px"} height={"21px"} className="zealy"/>
            </Link>
          
            <Link to='https://synthr.gitbook.io/home' className='social-media-wrapper' target="_blank">
              <Gitbook />
            </Link>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
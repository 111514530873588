import { useState } from 'react';
import Logo from "../assets/logo-light.png"; 
import { ReactComponent as Hamburger } from "../assets/hamburger.svg"; 
import { Link } from "react-router-dom";
import {ReactComponent as Caret} from "../assets/down-caret.svg";
import { ReactComponent as X } from "../assets/socials/xlogo.svg";
import { ReactComponent as Discord } from "../assets/socials/discord-icon.svg";
import { ReactComponent as Telegram } from "../assets/socials/telegram-icon.svg";
import { ReactComponent as Zealy } from '../assets/socials/zealy.svg';
import Cross from "../assets/cross.png";

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleOpenApp = () => {
    window.open("https://mvp.synthr.io", "_blank")
  }

  return(
    <header>
      <div className='site-container'>
        <div className={`d-flex d-md-none align-items-center justify-content-between mobile-header`} id='mobile-header'>
          <Link to='/' className='logo'>
            <img src={Logo} alt='' />
          </Link>
          
          <div 
            className='hamburger' 
            onClick={() => setShowNavbar(true)}
          >
            <Hamburger />
          </div>
        </div>

        <div className={`header-container ${showNavbar  ? 'active' : '' } d-flex align-items-md-center justify-content-md-between flex-column flex-md-row`}>
          {/* <div className='d-md-flex align-items-center justify-content-between'> */}
            <div className='d-flex align-items-center justify-content-between'>
              <Link to='/' className='logo'>
                <img src={Logo} alt='' />
              </Link>

              <div 
                className='close-button d-md-none' 
                onClick={() => setShowNavbar(false)}
              >
                <img src={Cross} alt='' />
              </div>
            </div>

            <div className='d-md-flex align-items-center navigation-wrapper'>
              {/* <Link to="/" className='link'> Home </Link> */}
              <Link to="https://synthr.gitbook.io/home/welcome/buidlonsynthr" target="_blank" className='link'> #BUIDLonSYNTHR </Link>
              {/* <Link to='https://synthrprotocol.gitbook.io/' target="_blank" className='link'> Docs </Link> */}
              <div className='connect-wrapper'>
                <div className='title-container'>
                  Docs
                  <Caret className='caret' />
                </div>
                <div className='links-wrapper'>
                  <Link to='https://synthr.gitbook.io/home' target="_blank">
                    GitBook
                  </Link>
                  <Link to='https://synthr.io/static/docs/Synthr_WP.pdf' target="_blank">
                    Whitepaper
                  </Link>
                </div>
              </div>
              <Link to="/sycredits" className='link'> syCREDITS </Link>
              
              <div className='connect-wrapper'>
                <div className='title-container'>
                  Community
                  <Caret className='caret' />
                </div>
                <div className='links-wrapper'>
                  <Link to='https://twitter.com/synthr_defi' target="_blank">
                    <div className='icons'>
                      <X />
                    </div>
                    X
                  </Link>
                  <Link to='https://discord.com/invite/KkUzFPanf5' target="_blank">
                    <div className='icons'>
                      <Discord />
                    </div>
                    Discord
                  </Link>
                  <Link to='https://t.me/synthrcommunitychannel' target="_blank">
                    <div className='icons'>
                      <Telegram />
                    </div>
                    Telegram
                  </Link>
                  <Link to='https://zealy.io/c/synthr/questboard' target="_blank">
                    <div className='icons'>
                      <Zealy className='zealy' />
                    </div>
                    Zealy
                  </Link>
                  {/* <Link to='https://synthr.gitbook.io/' target="_blank">
                    <div className='icons'>
                      <Gitbook />
                    </div>
                    Docs
                  </Link> */}
                </div>
              </div>
              
            </div>
          {/* </div> */}
          
          <button 
            className='launch-button'
            onClick={handleOpenApp}
          >
            LAUNCH APP
          </button>
        </div>
      </div>
    </header>
  )
}
export default Header;
import axios from "axios";

const syCreditsService = {};

const apiUrl = process.env.REACT_APP_BOT_API_HOST;
const apiKey = process.env.REACT_APP_DISCORD_BOT_API_KEY2;
const zealyApiUrl = process.env.REACT_APP_ZEALY_API_HOST;
const zealyApiKey = process.env.REACT_APP_ZEALY_API_KEY;

syCreditsService.getTotalTransaction = async () => {
  const url = `${apiUrl}/get_total_txcount`;
  const res = await axios({
    url,
    method : "GET",
    headers: {
      'x-api-key': apiKey,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, x-api-key'
    },
  })

  return res;
}

syCreditsService.getTotalTestnetUser = async () => {
  const url =  `${apiUrl}/get_total_users`;
  const res = await axios({
    url,
    method : "GET",
    headers: {
      'x-api-key': apiKey,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, x-api-key'
    },
  })

  return res;
}

syCreditsService.getLeaderboard = async () => {
  const url = `${apiUrl}/top_users?limit=50`;
  const res = await axios({
    url,
    method : "GET",
    headers: {
      'x-api-key': apiKey,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, x-api-key'
    },
  })

  return res;
}

syCreditsService.getCampaignLeaderboard = async () => {
  const url = `${apiUrl}/top_users?is_camp=${true}&limit=50`;
  const res = await axios({
    url,
    method : "GET",
    headers: {
      'x-api-key': apiKey,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, x-api-key'
    },
  })

  return res;
}

syCreditsService.getLogs = async () => {
  const url = `${apiUrl}/synth_logs`;
  const res = await axios({
    url,
    method : "GET",
    headers: {
      'x-api-key': apiKey,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, x-api-key'
    },
  })

  return res;
}

syCreditsService.SearchUser = async (address) => {
  const url = `${apiUrl}/search?wallet_address=${address}`;
  const res = await axios({
    url,
    method : "GET",
    headers: {
      'x-api-key': apiKey,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, x-api-key'
    },
  })

  return res;
}

syCreditsService.getTotalRewards = async () => {
  const url = `${apiUrl}/get_total_rewards`;
  const res = await axios({
    url,
    method : "GET",
    headers: {
      'x-api-key': apiKey,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, x-api-key'
    },
  })

  return res;
}

syCreditsService.zealyLeaderboard = async () => {
  const url = `${zealyApiUrl}/leaderboard`
  const res = await axios({
    url,
    method : "GET",
    headers: {
      'x-api-key': zealyApiKey,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, x-api-key'
    },
  })

  return res;
}

export default syCreditsService;
import { useEffect, useState } from "react";
import "../styles/twitterCard.scss";
import DetailsBg from "../assets/scorecard/scorecard-bg.png";
import BoxBg from "../assets/scorecard/gradient-frame.png"
import SynthrTestnetIcon from "../assets/scorecard/synthr-testnet-scorecard.png";
import Synthr from "../assets/scorecard/synth-token.png";
import Crusader from "../assets/scorecard/Crusader.png";
import Guardian from "../assets/scorecard/Guardian.png";
import Immortal from "../assets/scorecard/Immortal.png";
import Legend from "../assets/scorecard/Legend.png";
import Master from "../assets/scorecard/Master.png";
import Recruit from "../assets/scorecard/Recruit.png";
import Trooper from "../assets/scorecard/Trooper.png";
import Wizard from "../assets/scorecard/Wizard.png";

const Base_url = window.location.origin ?? '';
const TwitterCard = ({ data, element, dateAndTime, showCard, testnetUsers, testnetTnx }) => {
  const [details, set_details] = useState([])
  const getBadge = (badgeNumber) => {
    let badge = 'None';
    switch (badgeNumber) {
      case 0:
        badge = 'None';
        break;
      case 1:
        badge = 'Recruit';
        break;
      case 2:
        badge = 'Guardian';
        break;
      case 3:
        badge = 'Trooper';
        break;
      case 4:
        badge = 'Crusader';
        break;
      case 5:
        badge = 'Master';
        break;
      case 6:
        badge = 'Wizard';
        break;
      case 7:
        badge = 'Legend';
        break;
      case 8:
        badge = 'Immortal';
        break;
      default:
        badge = '-';
        break;
    }
    return badge;
  }

  const getBadgeImg = (badgeNumber) => {
    let badge_img = Synthr;
    switch (badgeNumber) {
      case 0:
        badge_img = Synthr;
        break;
      case 1:
        badge_img = Recruit;
        break;
      case 2:
        badge_img = Guardian;
        break;
      case 3:
        badge_img = Trooper;
        break;
      case 4:
        badge_img = Crusader;
        break;
      case 5:
        badge_img = Master;
        break;
      case 6:
        badge_img = Wizard;
        break;
      case 7:
        badge_img = Legend;
        break;
      case 8:
        badge_img = Immortal;
        break;
      default:
        badge_img = Synthr;
        break;
    }
    return badge_img;
  }
  
  useEffect(() => {
    if(data !== null && data.length === 1){
      const d = [
        {label: 'Transactions', value: data[0].transactions.toLocaleString('en-US')},
        {label: 'Role', value: getRole(data[0].role)},
        {label: 'Zealy XP', value: data[0].zealy_points.toLocaleString('en-US')},
        {label: 'Badge', value: getBadge(data[0].badge)},
        {label: 'Referrals', value: data[0].successful_referrals} 
      ]

      set_details(d);
    }
  }, [data])

  const getRole = (roleNumber) => {
    let role = '-';
    switch (roleNumber) {
      case 3:
        role = 'OG'
        break;
      case 2:
        role = 'EA'
        break;
      case 1:
        role = 'Public'
        break;
      default:
        role = '-'
        break;
    }
    return role;
  }

  return(
    <>
    {
      DetailsBg && SynthrTestnetIcon && BoxBg && data.length === 1 &&

      <div 
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "1080px",
          height: "1350px",
          background: "#0B1109",
          padding: "40px",
          zIndex: -1,
          opacity: showCard ? 1 : 0,
          display: showCard ? "block" : "none",
        }} 
        ref={element}
      >
        <div
          style={{
            position: 'relative',
            width: "100%",
            padding: "60px 24px 24px 24px",
            borderRadius: "50px",
            height: "fit-content"
          }}
        >
          <img 
            src={Base_url+DetailsBg} 
            alt="" 
            style={{
              position: 'absolute',
              left: '0',
              top: '0',
              // height: '100%',
              width: '100%',
              zIndex: 0,
            }}
          />
          <div
            style={{
              padding: "0 36px",
              position: 'relative'
            }}      
          >
            <img src={Base_url+SynthrTestnetIcon} alt='' style={{ height: "73.12px" }} />

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1.5fr 1fr",
                gap: "97px",
                rowGap: "54px",
                marginTop: "46px",
              }}  
            >
              {
                data && details.map((d, ind) => (
                  <div>
                    <div
                      style={{
                        color:  "#FFF",
                        textShadow: "0px 4px 32px rgba(0, 0, 0, 0.33)",
                        fontFamily: "Chakra Petch",
                        fontSize: "44px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "115%",
                      }}
                    > 
                      {d.label} 
                    </div>
                    <div
                      style={{
                        fontFamily: "Chakra Petch",
                        fontSize: "44px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "120%",
                        marginTop: "8px",
                        background: "linear-gradient(180deg, #F8FFFA 0%, #C0E4CD 100%)",
                        color: "#C0E4CD",
                        backgroundClip: "border-box",
                        WebkitBackgroundClip: "border-box",
                        WebkitTextFillColor: "#C0E4CD",
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        MozBackgroundClip: "text",
                        MozTextFillColor: "transparent",
                        color: "transparent",
                      }}
                    >
                      {d.value}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'relative',
              marginTop: "-39px",
            }}
          >
            <div 
              style={{
                position: "relative",
                width: "213px",
                height: "213px",
                marginRight: "56px",
                borderRadius: "50%",
                zIndex: 111,
              }}
            > 
              <div 
                style={{
                  position: "absolute",
                  content: '',
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "181px",
                  height: "177px",
                  flexShrink: 0,
                  borderRadius: "50%",
                  background: "#2FF6F7",
                  zIndex: -1,
                  boxShadow: "0px 0px 177.12px 0px rgba(47, 246, 247, 0.14), 0px 0px 103.32px 0px rgba(47, 246, 247, 0.29), 0px 0px 51.66px 0px #2FF6F7, 0px 0px 7.38px 0px #2FF6F7"
                }}
              />

              {data.length === 1 && 
                <img 
                  src={Base_url+getBadgeImg(data[0].badge)} 
                  alt=''  
                  style={{
                    height: "100%",
                    width: "100%",
                  }}  
                />
              }
            </div>
          </div>

          <div
            style={{
              padding: "68px 68px 68px 56px",
              background: "#050706",
              filter: 'drop-shadow(0px 4px 106.1px #000)',
              borderRadius: '20px 20px 50px 50px',
              gap: '30px',
              display: 'flex',
              flexDirection: 'column',
              marginTop: '-120px',
            }}  
          >
            <div
              style={{
                color: '#CAEED6',
                textShadow: '0px 4px 32px rgba(0, 0, 0, 0.33)',
                fontFamily: "Chakra Petch",
                fontSize: '42px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '115%',
                opacity: '0.8',
              }}    
            >
              @{data.length === 1 && data[0].discord_tag.length >= 15 ? data[0].discord_tag.slice(0, 15) + '...' : data[0].discord_tag}
            </div>
            <div
              style={{
                color: '#FFF',
                textShadow: '0px 4px 32px rgba(0, 0, 0, 0.33)',
                fontFamily: "Chakra Petch",
                fontSize: '42px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '115%',
              }}    
            >
              syCREDITS: 
              <span
                style={{
                  fontFamily: "Chakra Petch",
                  fontSize: "42px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "115%",
                  marginLeft: "24px",
                  background: "linear-gradient(180deg, #F8FFFA 0%, #C0E4CD 100%)",
                  color: "#C0E4CD",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "border-box",
                  WebkitTextFillColor: "#C0E4CD",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  MozBackgroundClip: "text",
                  MozTextFillColor: "transparent",
                  color: "transparent",
                }}
              >
                {data.length === 1 && data[0].total_reward.toLocaleString('en-US')}
              </span>
            </div>
          </div>
        </div>

        <div 
          style={{
            display: 'flex',
            alignItems: "center"
          }}
        >
          <div
            style={{
              height: 0,
              width: "100%",
              borderBottom: "1px solid rgba(176, 176, 176, 0.50)",
            }}
          />
          <div
            style={{
              position: 'relative',
              color: "#2BF5AB",
              fontFamily: "Monument Extended",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: 800,
              lineHeight: "115%",
              textTransform: "uppercase",
              textAlign: "center",
              textShadow: "0px 4px 32px rgba(0, 0, 0, 0.33)",
              margin: "38px 44px 32px 44px",
              zIndex: 1,
              flexShrink: '0',
            }}    
          >
            OVERALL STATS
          </div>
          <div
            style={{
              height: 0,
              width: "100%",
              borderBottom: "1px solid rgba(176, 176, 176, 0.50)",
            }}
          />
        </div>
        
        <div
          style={{
            display: "grid",
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: "18px",
          }}
        >
          <div
            style={{
              position: 'relative',
              width: "491px",
              display: 'grid',
              placeItems: 'center',
              gap: "10px",
              borderRadius: "32px",
              padding: "22px 0",
            }}
          >
            <img 
              src={BoxBg} 
              alt='' 
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            />
            <div
              style={{
                color: "#FFF",
                textShadow: "0px 4px 32px rgba(0, 0, 0, 0.33)",
                fontFamily: "Chakra Petch",
                fontSize: "36px",
                fontTtyle: "normal",
                fontWeight: 400,
                lineHeight: "115%", /* 41.4px */
                opacity: 0.7,
              }}    
            >Testnet users</div>
            <div
              style={{
                color: "#2BF5AB",
                textShadow: "0px 14px 44.6px #2BF5AB",
                fontFamily: "Chakra Petch",
                fontSize: "44px",
                fontTtyle: "normal",
                fontWeight: 700,
                lineHeight: "115%",
              }}  
            >{testnetUsers && testnetUsers.toLocaleString('en-US')}</div>
          </div>

          <div
            style={{
              position: 'relative',
              display: 'grid',
              placeItems: 'center',
              width: "491px",
              gap: "10px",
              borderRadius: "32px",
              padding: "22px 0",
            }}
          >
            <img 
              src={BoxBg} 
              alt='' 
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            />
            <div
              style={{
                color: "#FFF",
                textShadow: "0px 4px 32px rgba(0, 0, 0, 0.33)",
                fontFamily: "Chakra Petch",
                fontSize: "36px",
                fontTtyle: "normal",
                fontWeight: 400,
                lineHeight: "115%", /* 41.4px */
                opacity: 0.7,
              }}    
            >Testnet transactions</div>
            <div
              style={{
                color: "#2BF5AB",
                textShadow: "0px 14px 44.6px #2BF5AB",
                fontFamily: "Chakra Petch",
                fontSize: "44px",
                fontTtyle: "normal",
                fontWeight: 700,
                lineHeight: "115%",
              }}
            >{testnetTnx && testnetTnx.toLocaleString('en-US')}</div>
          </div>
        </div>

        <div
          style={{
            color:  '#FFF',
            textShadow: '0px 4px 32px rgba(0, 0, 0, 0.33)',
            fontFamily: "Chakra Petch",
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '115%',
            opacity: 0.5,
            marginTop: '34px',
            textAlign: 'center',
          }}
        >
          Generated on: {dateAndTime}
        </div>
      </div>
    }
    </>
  )
}
export default TwitterCard;

import SeiLogo from "../../assets/grants/new/SEI.png";
import Sui from "../../assets/grants/new/SUI.png";
import Metis from "../../assets/grants/new/METIS.png";
import Sharduim from "../../assets/grants/new/SHAREDEUM.png";
import Telos from "../../assets/grants/new/TELOS.png";
import Kardiachain from "../../assets/grants/new/KARDIACHAINX-1.png";
import AlphaZero from "../../assets/grants/new/ALEPH0.png";
import Redbelly from "../../assets/grants/new/REDBELLLY.png";
import Lightlink from "../../assets/grants/new/LIGHTLINK.png";
// import Obscuro from "../../assets/grants/new/obscure-logo.png";
import Ten from "../../assets/grants/new/TEN.png";
import Oasis from "../../assets/grants/new/OASIS-1.png";
import Firechain from "../../assets/grants/new/FIRECHAIN.png";
import Vanar from "../../assets/grants/new/vanar.png";
import CustomCarousel from "../../components/CustomCarousel";

const _Grants = [SeiLogo, Metis, Sui, Sharduim, AlphaZero, Oasis, Telos, Redbelly, Lightlink, Ten, Firechain, Kardiachain, Vanar];

const Grants = () => {
    return (
        <div className="ecoS-wrapper grants">
            <div className="ecoS-heading heading text-title-animation">
                {/* <AnimatedText data="GRANTS." /> */}
                GRANTS
            </div>
            <div className="tab-content-wrapper">
                <div className={`active ecoS-slider`}>
                    <CustomCarousel
                        logos={_Grants}
                        classnames="ecoS-carousel grants-carousel"
                        gap="22px"
                        />
                </div>
            </div>
        </div>
    );
}

export default Grants;
// import BoxAnim from "../../components/BoxAnim";
// Backers
import Kronos from "../../assets/backers/KRONOS.png";
import Outlier from "../../assets/backers/OUTLIER.png";
import NGC from "../../assets/backers/NGC.png";
import Axelar from "../../assets/backers/AXELAR FOUNDATION.png";
import Georglos from "../../assets/backers/GEORGIOS-new.png";
import DEXTools from "../../assets/backers/DEXT-FORCE.png";
import Cointelegraph from "../../assets/backers/COINTELEGRAPH.png";
import Dexynth from "../../assets/backers/DEXYNTH.png";
import Apex from "../../assets/backers/APEX.png";
import VC316 from "../../assets/backers/316-logo.png";
import OutOfStoke from "../../assets/backers/OUT-OF-DTOCK.png";
// import Aza from "../../assets/backers/aza-logo.png";
import Invst from "../../assets/backers/INVEST.png";
import MarsDao from "../../assets/backers/MARSDAO.png";
// import Alphabeth from "../../assets/backers/alphabet-logo.png";
import V from "../../assets/backers/V.png";
import CryptoSociety from "../../assets/backers/CRYPTO SOCIETY.png";
import Sudo from "../../assets/backers/SUDO-1.png";
import CSP from "../../assets/backers/CSP-DAO.png";
import Absoulta from "../../assets/backers/ABSOLUTA-DIGITAL.png";
import COINFLIP from "../../assets/backers/coinflip-1.png";
import Laser from "../../assets/backers/LASER-1.png";
import Nxgen from "../../assets/backers/NXGEN-1.png";
import Qlin from "../../assets/backers/qilin-lab-2.png";
import Milan from "../../assets/backers/milan.png";
// import ZBS from "../../assets/backers/zbs.png";
import Venture from "../../assets/backers/H5.png";
import DutchCrypto from "../../assets/backers/DUTCH.png";
import Iangles from "../../assets/backers/IANGLES-1.png";
import mvstm from "../../assets/backers/MVST.png";
import Spicy from "../../assets/backers/SPICY-1.png";
import Waterdrip from "../../assets/backers/WATERDRIP-1.png";
import NewTribe from "../../assets/backers/NEW-TRIBE.png"
import MorningStar from "../../assets/backers/MORNING-STAR.png";
import CustomCarousel from "../../components/CustomCarousel";
import Spykefast from "../../assets/backers/SPYKEFAST.png";
import ZBS from "../../assets/backers/ZBS CAPITAL.png";
import Alphabet from "../../assets/backers/Alphabeth-1.png";
import Oriole from "../../assets/backers/Oriole.png";
import X21 from "../../assets/backers/x21.png";
import SL2 from "../../assets/backers/SL2.png";
import Wilsen from "../../assets/backers/wilsen-1.png";
import RR from "../../assets/backers/RR.png";
// import BoxAnim from "../../components/BoxAnim";
import JaSONMA from "../../assets/backers/jason-ma-new.png";
import Robert from "../../assets/backers/robert.png";
import Arthur from "../../assets/backers/arthur.png";
import Karim from "../../assets/backers/karim.png";


const Backers1 = [Kronos, Outlier, NGC, Laser, Iangles, Waterdrip, Axelar, Cointelegraph, NewTribe, MorningStar, DEXTools, V, Sudo, VC316, Qlin, MarsDao, OutOfStoke, CSP, Absoulta, CryptoSociety, Invst, Spicy, Apex, Venture, Dexynth, DutchCrypto, mvstm, Spykefast, ZBS, Alphabet, Oriole, X21, SL2, RR, Nxgen ]
const Backers2 = [Georglos, COINFLIP, Wilsen, Milan, JaSONMA, Robert, Arthur, Karim, Georglos, COINFLIP, Wilsen, Milan, JaSONMA, Robert, Arthur, Karim,]

const BackersAndPartners = () => {

  return(
    <div className="ecoS-wrapper backed">
      <div className="ecoS-heading text-title-animation">
        BACKED BY THE BEST IN THE SPACE
        </div>
      <div className="tab-content-wrapper">
        <div className={`active ecoS-slider atm`}>
            <CustomCarousel
                logos={Backers1}
                classnames="ecoS-carousel grants-carousel"
                gap="22px"
                />
        </div>
        <div delay="0.7" className={`active ecoS-slider btm`}>
            <CustomCarousel
                logos={Backers2}
                classnames="ecoS-carousel grants-carousel"
                gap="22px"
                />
        </div>
      </div>
  </div>
    // <section className='backers-and-partners-section' >
    //   <div className='site-container'>
    //     <div className='title'> INVESTORS </div>
    //     <CustomCarousel 
    //       logos={Backers1}
    //       classnames="backers-carousel backers-1"
    //       prev=".swiper-nav-prev"
    //       next='.swiper-nav-next'
    //       rtl={true}
    //     />

    //     <div className="second-row-backers">
    //       <CustomCarousel 
    //         logos={Backers2}
    //         classnames="backers-carousel backers-2"
    //         prev=".swiper-nav-prev"
    //         next='.swiper-nav-next'
    //       />
    //     </div>
    //   </div>
    // </section>
  )
}
export default BackersAndPartners